import {shouldPolyfill as shouldPolyfillLocale} from '@formatjs/intl-locale/should-polyfill';
import {shouldPolyfill as shouldPolyfillRelativeTimeFormat} from '@formatjs/intl-relativetimeformat/should-polyfill';
import {shouldPolyfill as shouldPolyfillPluralRules} from '@formatjs/intl-pluralrules/should-polyfill';

const polyfill = async function () {
    if (shouldPolyfillLocale()) {
        console.log('Polyfill Locale');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfillRelativeTimeFormat()) {
        console.log('Polyfill RelativeTimeFormat');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-relativetimeformat/polyfill');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-relativetimeformat/locale-data/en');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-relativetimeformat/locale-data/fr');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-relativetimeformat/locale-data/zh');
    }

    if (shouldPolyfillPluralRules()) {
        console.log('Polyfill PluralRules');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-pluralrules/polyfill');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-pluralrules/locale-data/en');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-pluralrules/locale-data/fr');
        await import(/* webpackChunkName "polyfill-intl"*/'@formatjs/intl-pluralrules/locale-data/zh');
    }
    if (typeof String.prototype.replaceAll === 'undefined') {
        console.log('Polyfill es');
        await import(/* webpackChunkName "polyfill-es"*/'core-js/es');
        await import(/* webpackChunkName "polyfill-es"*/'core-js/features/string');
    }
};

export default polyfill;