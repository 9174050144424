/* eslint-disable @next/next/inline-script-id */
import React from 'react';
import Head from 'next/head';
import axios from '../../utils/axios';
import { connect } from 'react-redux';
import { openAlert } from 'src/redux/alert';
import { openSnackBar } from 'src/redux/snackbar';
import LoginModal from '../Modal/login-modal/login-modal';
import Footer from '../Core/footer/footer';
import MenuBar from '../Core/menu-bar/menu-bar';
import LoadingBar from '../Core/loading/loading';
import SnackBar from '../Core/snack-bar/snack-bar';
import Alert from '../Core/alert/alert';
import Script from 'next/script';
import plausible from '../../utils/plausible';
import styles from '../../styles/error.module.scss';
import Swal from 'sweetalert2';

import { 
    Col,
    Text
} from '@nextui-org/react';
import { Browser } from 'react-kawaii';
import { injectIntl } from 'react-intl';
import { AUTHENTICATE, DEAUTHENTICATE, getUserInfo } from 'src/redux/auth';
import bindAll from 'lodash.bindall';
import QuizTips from '../Core/menu-bar/quiz-tips';
import Router from 'next/router';
import dayjs from 'dayjs';
import ErrorBoundary from './ErrorBoundary';
import Toast from '../../utils/toast';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.setAuthorization();
        bindAll(this, [
            'handleMessage',
            'setAuthorization',
        ]);
        this.state = {
            hasError: false,
            loading: false
        };
        if (typeof window !== 'undefined') {
            this.channel = window.BroadcastChannel ? new BroadcastChannel('clip_community') : null;
        }
    }
    componentDidMount () {
        this.channel && this.channel.addEventListener('message', this.handleMessage);
        // this.setAuthorization();
        window.clipAlert = this.props.openAlert;
        window.domLoadTime = Date.now();
        window.clipSnackbar = (type, message) => Toast(message, type);
        
        Router.events.on("routeChangeStart", () => {
            this.setState({loading: true});
        });

        Router.events.on("routeChangeComplete", () => {
            this.setState({loading: false});
        });
    }
    componentDidUpdate (prevProps) {
        // this.setAuthorization();
        if (this.state.hasError && prevProps !== this.props) {
            this.setState({hasError: false});
        } 
    }
    setAuthorization () {
        if (!this.props.token) return;
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.props.token}`;
    }
    static getDerivedStateFromError(error) {    
        return { hasError: true, errorDetail: error, errorTime: Date.now() };
    }
    handleMessage (event) {
        // console.log(event);
        if (event.data.source === window.domLoadTime) return;
        if (event.data.type === 'loginStatusChanged') {
            if (event.data.data.isLogin) {
                this.props.setUserToken(event.data.data.token);
                this.props.getUserInfo();
            } else {
                this.props.onLogout();
            }
        }
    }
    componentDidCatch (error, errorInfo) {
        plausible('ClientError', {error: error.message});
        console.error({ error, errorInfo });
    }
    componentWillUnmount (){
        this.channel && this.channel.removeEventListener('message', this.handleMessage);
    }
    render() {
        const {
            children,
            loginModalOpen,
            title,
            alert,
            snackbar,
            intl,
            hideMenuBar,
            hideFooter
        } = this.props;
        return (
            <>
                <Script id="globalThis" strategy="beforeInteractive">
                    {`this.globalThis || (this.globalThis = this)`}
                </Script>
                <Script 
                    src='https://static.cloudflareinsights.com/beacon.min.js' 
                    data-cf-beacon='{"token": "be81aef6a14b4de184ec364e1741eb65"}'
                />
                <Head>
                    <title>{typeof title === 'object' ?  `${title.intl ? intl.formatMessage({id: title.front}) : title.front} - ${intl.formatMessage({id:'menubar.clipcommunity'})} | ${title.after ? title.intl ? intl.formatMessage({id: title.after}) : title.after : intl.formatMessage({id:'title.after', defaultMessage: 'Scratch交流平台'})}` : (title ? `${title} - `: '') + `${intl.formatMessage({id:'menubar.clipcommunity'})} | ${intl.formatMessage({id:'title.after', defaultMessage: 'Scratch交流平台'})}`}</title>
                    <meta name="keywords" content="别针社区,ClipCC,CodingClip,图形化编程,Scratch,编程社区,西瓜创客,少儿编程,儿童编程,Scratch中文社区,Scratch中国,scratch官网,A营,阿尔法营,阿儿法营,aerfaying,稽木世界,积木世界,编程猫社区,西瓜编程,西瓜社区,学而思编程,网易有道卡搭,小码王,核桃编程,迷你编程,动画,Scratch云变量,Scratch云列表,Scratch物理引擎,Scratch物理碰撞,scratch物理仿真,Scratch联机,Turbo warp,Scratch游戏制作,Scratch游戏代码, Scratch游戏制作,Scratch游戏教程,Scratch游戏案例,Scratch作品教程,scratch作业,scratch素材,信息课作业,游戏,小说,创意,创客,教程,小学信息技术,编程,机器人,可视化编程,图形化编程,STEAM,Python,HTML,Turtle,Pygame,乐高,素质教育,独立游戏,游戏制作,动画制作,创造力,逻辑思维,小游戏"/>
                    <meta name="description" content="别针社区是由中国的一小群 Scratcher 自己编写并运营的公益性 Scratch 社区。"/>
                </Head>
                <LoadingBar isRouteChanging={this.state.loading} />
                <SnackBar type={snackbar.type} message={snackbar.message} open={snackbar.open} />
                {!hideMenuBar && <MenuBar />}
                {this.props.userPermission.includes('NEWUSER') && !hideMenuBar && <QuizTips />}
                <ErrorBoundary>
                    {children}
                </ErrorBoundary>
                <LoginModal open={loginModalOpen} />
                <Alert open={alert.open} title={alert.title} content={alert.message} onClose={alert.callback} />
                {!hideFooter &&<Footer />}
            </>
        );
    }
}

const mapStateToProps = state => ({
    snackbar: {
        open: state.snackbar.open,
        type: state.snackbar.messageType,
        message: state.snackbar.message
    },
    alert: {
        open: state.alert.open,
        title: state.alert.title,
        message: state.alert.message,
        callback: state.alert.callback
    },
    loginModalOpen: state.modal.login,
    token: state.auth.token,
    userPermission: state.auth.permission
});

const mapDispatchToProps = dispatch => ({
    openAlert: (title, message) => dispatch(openAlert(title, message)),
    setUserToken: (token) => dispatch({type: AUTHENTICATE, token, noCookie: true}),
    getUserInfo: () => dispatch(getUserInfo()),
    onLogout: () => dispatch({type: DEAUTHENTICATE, noCookie: true}),
    openSnackBar: (type, message, timeout) => dispatch(openSnackBar(type, message, timeout))
});


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout));
