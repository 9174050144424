import styles from './snack-bar.module.scss';
import NoSsr from '../noSsr';
import Swal from 'sweetalert2';
import Proptypes from 'prop-types';
import { 
    InfoCircle,
    Danger,
    ShieldDone,
} from 'react-iconly';
import classNames from 'classnames';

function SnackBar({type, message, icon, open}) {
    return (
        <NoSsr>
            <div className={classNames(styles.snackbar,{
                [styles.open]: open
            })}>
                <div className={styles[type]}>
                    {icon ? 
                        icon : 
                        type === 'warning' ? <Danger /> 
                            : type === 'error' ? <InfoCircle /> 
                                :
                                type === 'success' ? <ShieldDone /> :
                                    null}
                    {message}
                </div>
            </div>
        </NoSsr>
    );
}

SnackBar.propTypes = {
    icon: Proptypes.node,
    open: Proptypes.bool,
    type: Proptypes.string,
    message: Proptypes.string.isRequired
};

SnackBar.defaultProps = {
    type : 'warning',

};

export default SnackBar;