/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import {
    Modal,
    Button,
    Input,
    Col,
    Text,
    Spacer,
    Tooltip,
    Loading
} from '@nextui-org/react';
import { IceCream, Chocolate } from 'react-kawaii';
import { User, Password, Call, ShieldDone, Send } from 'react-iconly';
import { connect } from 'react-redux';
import { closeLoginModal } from '../../../redux/modal';
import PropTypes from 'prop-types';
import styles from './login-modal.module.scss';

import Link from 'next/link';
import Image from 'next/image';
import axios from '../../../utils/axios';
import bindAll from 'lodash.bindall';
import { login } from '../../../redux/auth';
import captcha from 'src/utils/captcha';
import { openSnackBar } from '../../../redux/snackbar';
import classNames from 'classnames';
import ReCaptcha from '@/components/Core/captcha/recaptcha';
import Router from 'next/router';
import * as RSA from 'src/utils/rsa';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { getCloudConfig } from 'src/utils/cloud_config';

defineMessages({
    phonenumber : {defaultMessage: '手机号', id: 'login.form.phonenumber'},
    username : {defaultMessage: '用户名', id: 'login.form.username'},
    password : {defaultMessage: '密码', id: 'login.form.password'},
    captcha : {defaultMessage: '验证码', id: 'login.form.captcha'},
});

class LoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.registerPhoneBlockList = [
            '165',
            '1703',
            '1705',
            '1706',
            '167',
            '1704',
            '1707',
            '1708',
            '1709',
            '171',
            '1700',
            '1701',
            '1702'
        ];
        this.state = {
            username: '',
            password: '',
            confirmpassword: '',
            error: {
                username: null,
                password: null,
                confirmpassword: null,
                phone: null,
                smsCode: null,
                captcha: null
            },
            phoneSuffix: '+86',
            phone: '',
            isLogin: true,
            smsCode: '',
            sendWaitTime: 0,
            answer: '',
            trueAnswer: ''
        };
        bindAll(this, [
            'handleChange',
            'handleClickLogin',
            'handleEnter',
            'handleClickSendSmsCode',
            'handleClickRegister',
            'fetchCaptcha',
            'handleCaptchaChange'
        ]);
        this.recaptchaRef = React.createRef();
        this.fetchCaptcha();
    }
    handleChange(form, event) {
        this.setState({
            [form]: event.target.value
        });
    }
    handleCaptchaChange (value) {
        // todo
    }
    componentDidUpdate() {
        document.body.style.overflow = 'auto';
    }
    handleEnter(event) {
        if (event.key === 'Enter') {
            const { isLogin } = this.state;
            if (isLogin) {
                this.handleClickLogin();
            } else {
                this.handleClickRegister();
            }
        }
    }
    phoneIsAllow(phone) {
        for (const key in this.registerPhoneBlockList) {
            if (phone.startsWith(this.registerPhoneBlockList[key])) {
                return false;
            }
        }
        return true;
    }
    async handleClickLogin() {
        const {
            username,
            password,
            answer,
            trueAnswer,
            isLogin
        } = this.state;
        if (username.trim().length < 1) {
            this.setState(Object.assign({}, this.state, { error: { username: this.props.intl.$t({
                defaultMessage: '用户名不能为空',
                id: 'login.usernameEmpty'
            }) } }));
            return;
        }
        if (password.trim().length < 1) {
            this.setState(Object.assign({}, this.state, { error: { password: this.props.intl.$t({
                defaultMessage: '密码不能为空',
                id: 'login.passwordEmpty'
            }) } }));
            return;
        }
        this.setState({
            logining: true
        });
        const encryptPassword = RSA.encrypt(password.trim());
        try {
            const userInfo = await this.props.onLogin(username.trim(), encryptPassword);
            if (userInfo) {
                this.props.openSnackBar(this.props.intl.$t({
                    defaultMessage: '登录成功！',
                    id: 'login.success'
                }), 'success');
                this.props.onClose();
            }
        } catch (err) {
            const { message, response } = err;
            if (response && response.status === 401) {
                this.setState(Object.assign({}, this.state, {logining: false ,error: { password: this.props.intl.$t({
                    defaultMessage: '用户名或密码错误',
                    id: 'login.usernameOrPasswordWrong'
                }) } }));
                return;
            }
            if (message === 'require captcha') {
                this.setState({
                    logining: false
                });
                const token = await this.recaptchaRef.current.executeAsync();
                this.setState({
                    logining: true
                });
                if (!token) {
                    this.setState(Object.assign({}, this.state, { error: { phone: this.props.intl.$t({
                        defaultMessage: 'ReCaptcha 失效 请重试',
                        id: 'login.error.recaptcha'
                    }) } }));
                    this.setState({
                        logining: false
                    });
                    return;
                }
                try {
                    const userInfo = await this.props.onLogin(username.trim(), encryptPassword, token);
                    if (userInfo) {
                        this.props.openSnackBar(this.props.intl.$t({
                            defaultMessage: '登录成功！',
                            id: 'login.success'
                        }), 'success');
                        this.props.onClose();
                        return;
                    }
                } catch (err) {
                    this.recaptchaRef.current.reset();
                    const { message, response } = err;
                    if (response && response.status === 401) {
                        this.setState(Object.assign({}, this.state, {logining: false ,error: { password: this.props.intl.$t({
                            defaultMessage: '用户名或密码错误',
                            id: 'login.usernameOrPasswordWrong'
                        }) } }));
                        return;
                    }
                    this.setState({
                        logining: false
                    });
                    this.props.openSnackBar(this.props.intl.$t({
                        defaultMessage: '登录失败({status})：{message} ',
                        id: 'login.error'
                    }, {message, status: response.status}), 'error');
                    return;
                }
                
            }
            this.setState({
                logining: false
            });
            this.props.openSnackBar(this.props.intl.$t({
                defaultMessage: '登录失败({status})：{message} ',
                id: 'login.error'
            }, {message, status: response.status}), 'error');
        }
    }
    async handleClickSendSmsCode() {
        const { phone, sendWaitTime, phoneSuffix } = this.state;
        if (sendWaitTime > 0) return;
        if (phone.trim().length < 1) {
            this.setState(Object.assign({}, this.state, { error: { phone: this.props.intl.$t({
                defaultMessage: '手机号不能为空',
                id: 'login.error.phoneEmpty'
            }) } }));
            return;
        }
        if (!/^[1]([3-9])[0-9]{9}$/.test(phone) && phoneSuffix === '+86') {
            this.setState(Object.assign({}, this.state, { error: { phone: this.props.intl.$t({
                defaultMessage: '手机号格式不正确',
                id: 'login.error.phoneFormatInvalid'
            }) } }));
            return;
        }
        if (!this.phoneIsAllow(phone) && phoneSuffix === '+86') {
            this.setState(Object.assign({}, this.state, { error: { phone: this.props.intl.$t({
                defaultMessage: '当前手机号不可用于注册',
                id: 'login.error.phoneInvalid'
            }) } }));
            return;
        }
        this.setState(Object.assign({}, this.state, { error: { phone: null } }));
        const token = await this.recaptchaRef.current.executeAsync();
        if (!token) {
            this.setState(Object.assign({}, this.state, { error: { phone: this.props.intl.$t({
                defaultMessage: 'ReCaptcha 失效 请重试',
                id: 'login.error.recaptcha'
            }) } }));
            return;
        }
        
        try {
            axios.post('/auth/getSignUpCode', {
                phone : `${phoneSuffix}${phone}`,
                captchaToken: token
            })
                .then(() => {
                    this.setState({sendWaitTime: 90});
                    this.props.openSnackBar(this.props.intl.$t({
                        defaultMessage: '验证码发送成功',
                        id: 'login.sendSignUpCodeSuccess'
                    }), 'success');
                })
                .catch(e => {
                    if (e.response.code === 429) {
                        this.props.openSnackBar(this.props.intl.$t({
                            defaultMessage: '验证码发送过于频繁，请稍后再试',
                            id: 'login.error.code429'
                        }), 'error');
                    } else if (e.response.data?.message === 'Phone already exists') {
                        this.props.openSnackBar(this.props.intl.$t({
                            defaultMessage: '当前手机号已注册账号',
                            id: 'login.error.phoneAlready'
                        }), 'error');
                    } else if (e.response.data?.message === 'Captcha invalid') {
                        this.props.openSnackBar('Recaptcha 验证失败，请尝试重新验证', 'error');
                    }
                    else {
                        this.props.openSnackBar(this.props.intl.$t({
                            defaultMessage: '验证码发送失败: {message}',
                            id: 'login.error.sendFail'
                        }, {message: e.response.data?.message}), 'error');
                    }
                    this.setState({sendWaitTime: 0});
                })
                .finally(() => {
                    this.recaptchaRef.current.reset();
                    this.interval = setInterval(() => {
                        if (this.state.sendWaitTime < 1) {
                            return clearInterval(this.interval);
                        }
                        this.setState({
                            sendWaitTime: this.state.sendWaitTime - 1
                        });
                    }, 1000);
                });
        } catch (e) {
            
        }
    }
    async fetchCaptcha () {
        const [url, mf] = await captcha();
        this.setState({
            captcha: url,
            trueAnswer: mf
        });
    }
    async handleClickRegister() {
        const {
            username,
            phone,
            smsCode,
            answer,
            trueAnswer,
            phoneSuffix,
            confirmpassword,
            password
        } = this.state;
        if (username.trim().length < 1) {
            this.setState(Object.assign({}, this.state, { error: { username: this.props.intl.$t({
                defaultMessage: '用户名不能为空',
                id: 'login.error.usernameEmpty'
            }) } }));
            return;
        }
        if (phone.trim().length < 1) {
            this.setState(Object.assign({}, this.state, { error: { phone: this.props.intl.$t({
                defaultMessage: '手机号不能为空',
                id: 'login.error.phoneEmpty'
            }) } }));
            return;
        }
        if (smsCode.trim().length < 1) {
            this.setState(Object.assign({}, this.state, { error: { smsCode: this.props.intl.$t({
                defaultMessage: '验证码不能为空',
                id: 'login.error.smsCodeEmpty'
            }) } }));
            return;
        }
        if (password.trim().length < 1) {
            this.setState(Object.assign({}, this.state, { error: { password: this.props.intl.$t({
                defaultMessage: '密码不能为空',
                id: 'login.error.passwordEmpty'
            }) } }));
            return;
        }

        if (password != confirmpassword) {
            this.setState(Object.assign({}, this.state, { error: { confirmpassword: this.props.intl.$t({
                defaultMessage: '两次输入的密码不一致',
                id: 'login.error.passwordconfirmError'
            }) } }));
            return;
        }
        /*
        if(answer !== trueAnswer) {
            this.setState(Object.assign({}, this.state, { error: { captcha: '验证码错误' } }));
            return;
        }
        */
        const encryptPassword = RSA.encrypt(password.trim());
        
        try {
            await axios.post('/auth/signup', {
                name: username.trim(),
                password: encryptPassword,
                phone: `${phoneSuffix}${phone}`,
                code: smsCode.trim()
            });

            this.props.openSnackBar(this.props.intl.$t({
                defaultMessage: '注册成功！欢迎加入别针社区！',
                id: 'login.signUpSuccess'
            }), 'success');
            this.props.onClose();
            this.setState({isLogin: true}, () => {
                this.handleClickLogin();
            });
        } catch (e) {
            if (e.response.data?.message === 'Name cannot contain spaces') {
                this.props.openSnackBar('用户名无效：不得包含空格', 'error');
            } else if (e.response.data?.message === 'Phone code invalid') {
                this.props.openSnackBar('手机验证码无效', 'error');
            } else {
                this.props.openSnackBar('注册失败: ' + e.response.data?.message, 'error');
            }
        }
    }
    render() {
        const login = (
            <div className={styles.content}>
                <Modal.Header>
                    <Col>
                        <IceCream size={100} mood="blissful" color="#FDA7DC" />
                        <Text id="modal-title" size={18}>
                            <FormattedMessage defaultMessage='欢迎回来！' id='login.welcome' />
                        </Text>
                        <Text size={14} color="#a1a1a1"><FormattedMessage defaultMessage='来看看社区的新变化吧!' id='login.intro' /></Text>
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        value={this.state.username}
                        onChange={event => this.handleChange('username', event)}
                        fullWidth
                        onKeyDown={this.handleEnter}
                        color="primary"
                        contentLeft={<User />}
                        placeholder={this.props.intl.$t({defaultMessage: '用户名 / 邮箱 / 手机号', id: 'login.form.usernamephoneemail'})}
                        autoComplete="on"
                        className={styles.input}
                        status={this.state.error.username ? 'error' : null}
                        helperText={this.state.error.username}
                        helperColor="error"
                    />
                    <Spacer y={0.04} className={classNames({
                        [styles.helper]: !this.state.error.username
                    })} />
                    <Input.Password
                        clearable
                        value={this.state.password}
                        onChange={event => this.handleChange('password', event)}
                        fullWidth
                        onKeyDown={this.handleEnter}
                        color="primary"
                        className={styles.input}
                        contentLeft={<Password />}
                        placeholder={this.props.intl.$t({defaultMessage: '密码', id: 'login.form.password'})}
                        autoComplete="on"
                        status={this.state.error.password ? 'error' : null}
                        helperText={this.state.error.password}
                        helperColor="error"
                    />
                    <Spacer y={0.04} className={classNames({
                        [styles.helper]: !this.state.error.password
                    })} />
                </Modal.Body>
                <Modal.Footer>
                    <Button auto light onPress={() => {
                        if (!getCloudConfig('allowreg', true)) return this.props.openSnackBar('当前尚未开放注册，敬请期待！');
                        this.setState({ isLogin: false });
                    }}>
                        <FormattedMessage id='general.signUp' defaultMessage='没有账户？' />
                    </Button>
                    <Button auto light color="secondary" onPress={ () => window.open('/forgotpassword') }>
                        <FormattedMessage id='login.forgotpassword' defaultMessage='忘记密码？' />
                    </Button>
                    <Button auto onPress={this.handleClickLogin} disabled={this.state.logining}>
                        {this.state.logining ? <Loading color="currentColor" size="sm" /> : <FormattedMessage id='general.login' defaultMessage='登录' />}
                    </Button>
                </Modal.Footer>
            </div>
        );
        const register = (
            <div className={styles.content}>
                <Modal.Header>
                    <Col>
                        <Chocolate size={100} mood="happy" color="#A6E191" />
                        <Text id="modal-title" size={18}>
                            <FormattedMessage id='login.signup.title' defaultMessage='注册'/>
                        </Text>
                        <Text size={14} color="#a1a1a1"><FormattedMessage id='login.signup.intro' defaultMessage='有朋自远方来，不亦乐乎？'/></Text>
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        clearable
                        value={this.state.username}
                        onChange={event => this.handleChange('username', event)}
                        fullWidth
                        className={styles.input}
                        onKeyDown={this.handleEnter}
                        color="primary"
                        contentLeft={<User />}
                        placeholder={this.props.intl.$t({defaultMessage: '用户名', id: 'login.form.username'})}
                        status={this.state.error.username ? 'error' : null}
                        helperText={this.state.error.username}
                        helperColor="error"
                    />
                    <Spacer y={0.04} className={classNames({
                        [styles.helper]: !this.state.error.username
                    })} />
                    {/* <select>
                        <option>中国大陆 (+86)</option>
                        <option disabled>中国香港 (+852)</option>
                        <option disabled>中国澳门 (+853)</option>
                        <option disabled>中国台湾 (+886)</option>
                    </select> */}
                    <Input
                        clearable
                        value={this.state.phone}
                        onChange={event => this.handleChange('phone', event)}
                        fullWidth
                        onKeyDown={this.handleEnter}
                        color="primary"
                        className={styles.input}
                        labelLeft={<select name='phone' onChange={event => this.handleChange('phoneSuffix', event)}>
                            <option value='+86'>+86（中国大陆）</option>
                            <option value='+852'>+852（中国香港）</option>
                            <option value='+853'>+853（中国澳门）</option>
                            <option value='+886'>+886（中国台湾）</option>
                        </select>}
                        contentLeft={(<>
                            <Call />
                        </>)}
                        placeholder={this.props.intl.$t({defaultMessage: '手机号', id: 'login.form.phonenumber'})}
                        status={this.state.error.phone ? 'error' : null}
                        helperText={this.state.error.phone}
                        helperColor="error"
                    />
                    <Spacer y={0.04} className={classNames({
                        [styles.helper]: !this.state.error.phone
                    })} />
                    <Input
                        clearable
                        value={this.state.smsCode}
                        onChange={event => this.handleChange('smsCode', event)}
                        fullWidth
                        onKeyDown={this.handleEnter}
                        color="primary"
                        contentLeft={<Send />}
                        contentRightStyling={false}
                        contentRight={<Button auto light disabled={!!this.state.sendWaitTime} onPress={this.handleClickSendSmsCode}>{this.state.sendWaitTime > 0 ? `${this.props.intl.$t({defaultMessage: '发送验证码', id: 'login.form.sendCaptcha'})}（${this.state.sendWaitTime}）` : this.props.intl.$t({defaultMessage: '发送验证码', id: 'login.form.sendCaptcha'})}</Button>}
                        className={styles.input}
                        placeholder={this.props.intl.$t({defaultMessage: '验证码', id: 'login.form.captcha'})}
                        status={this.state.error.smsCode ? 'error' : null}
                        helperText={this.state.error.smsCode}
                        helperColor="error"
                    />
                    <Spacer y={0.04} className={classNames({
                        [styles.helper]: !this.state.error.smsCode
                    })} />
                    <Input.Password
                        clearable
                        value={this.state.password}
                        onChange={event => this.handleChange('password', event)}
                        fullWidth
                        onKeyDown={this.handleEnter}
                        color="primary"
                        contentLeft={<Password />}
                        className={styles.input}
                        placeholder={this.props.intl.$t({defaultMessage: '密码', id: 'login.form.password'})}
                        status={this.state.error.password ? 'error' : null}
                        helperText={this.state.error.password}
                        helperColor="error"
                    />
                    <Input.Password
                        clearable
                        value={this.state.confirmpassword}
                        onChange={event => this.handleChange('confirmpassword', event)}
                        fullWidth
                        onKeyDown={this.handleEnter}
                        color="primary"
                        contentLeft={<Password />}
                        className={styles.input}
                        placeholder={this.props.intl.$t({defaultMessage: '确认密码', id: 'login.form.confirmpassword'})}
                        status={this.state.error.confirmpassword ? 'error' : null}
                        helperText={this.state.error.confirmpassword}
                        helperColor="error"
                    />
                    <div className={styles.captcha}>
                        <Input
                            css={{
                                marginRight: '1rem'
                            }}
                            onKeyDown={this.handleEnter}
                            placeholder={this.props.intl.$t({defaultMessage: '注册码', id: 'login.form.signUpCode'})}
                            contentLeft={<ShieldDone />}
                            className={styles.input}
                            onChange={event => this.handleChange('answer', event)}
                            status={this.state.error.captcha ? 'error' : null}
                            helperText={this.state.error.captcha}
                            helperColor="error"
                        />
                        <Tooltip
                            css={{zIndex: 9999}}
                            content={
                                <>
                                    <Text size={14}>请在下方输入框中输入对应的化学式或朋友给你的邀请码</Text>
                                    <Text size={14}>点击图片即可刷新</Text>
                                </>
                            }
                        >
                            <img
                                unoptimized
                                draggable={false}
                                alt="captcha"
                                src={this.state.captcha}
                                onPress={this.fetchCaptcha}
                            />
                        </Tooltip>
                    </div>
                    <Text className={styles.rule} size={12}>
                        <FormattedMessage 
                            id='login.form.signupRule'
                            defaultMessage='在注册之前，请不要忘记阅读我们的 {rule} 呦'
                            values={{
                                rule: <Link href='/legal/rule' target='_blank' passHref>
                                    <a>
                                        <FormattedMessage 
                                            defaultMessage='社区守则' 
                                            id='login.form.rule'
                                        />
                                    </a>
                                </Link>
                            }}
                        />
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto light onPress={() => this.setState({ isLogin: true })}>
                        <FormattedMessage id='general.alreadyAccount' defaultMessage='已有账户？' />
                    </Button>
                    <Button auto onPress={this.handleClickRegister}>
                        <FormattedMessage id='general.signup' defaultMessage='注册' />
                    </Button>
                </Modal.Footer>
            </div>
        );
        return (
            <>
                <Modal
                    width={420}
                    closeButton
                    aria-labelledby="login"
                    open={this.props.open}
                    onClose={this.props.onClose}
                    className={styles.modal}
                >
                    <ReCaptcha 
                        ref={this.recaptchaRef} 
                        size="invisible"
                        onChange={this.handleCaptchaChange}
                    />
                    {this.state.isLogin ? login : register}
                </Modal>
            </>
        );
    }
}


LoginModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    onLogin: async (username, password, captcha)     => await dispatch(login('pass', null, null, username, password, null, captcha)),
    onClose: () => dispatch(closeLoginModal()),
    openSnackBar: (message, type) => dispatch(openSnackBar(type, message))
});

export default injectIntl(connect(null, mapDispatchToProps)(LoginModal));