import axios from '../utils/axios';
import oaxios from 'axios';
import qs from 'qs';
import { HYDRATE } from "next-redux-wrapper";
import { removeCookie, setCookie } from 'src/utils/cookie';

export const AUTHENTICATE = 'user/authenticate';
export const DEAUTHENTICATE = 'user/deauthenticate';
export const SET_USERINFO = 'user/setuserinfo';
export const SET_PERMISSIONS = 'user/setpermissions';

let channel;
if (typeof window !== 'undefined' && typeof BroadcastChannel !== 'undefined') {
    channel = new BroadcastChannel('clip_community');
}

const initialState = {
    token: null,
    permission: [],
    user: {
        isLogin: false,
        userInfo: null
    }
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
    case HYDRATE:
        return Object.assign({}, state, {...action.payload.auth});
    case SET_PERMISSIONS:
        return Object.assign({}, state, {permission: action.permission});
    case AUTHENTICATE:
        if (typeof window !== 'undefined') {
            if (!action.noCookie) {
                oaxios.post('/api/setAuthToken', {
                    token: action.token,
                });
            }
            if (state.token !== action.token) {
                channel && channel.postMessage({type: 'loginStatusChanged', data: {
                    isLogin: true,
                    token: action.token
                }, source: window.domLoadTime});
                axios.defaults.headers.common['Authorization'] = `Bearer ${action.token}`;
            }
        } 
        return Object.assign({}, state, {token: action.token});
    case DEAUTHENTICATE:
        if (typeof window !== 'undefined' && !action.noCookie) {
            if (!action.noCookie) {
                oaxios.post('/api/setAuthToken', {
                    token: 'removed',
                });
            }
            if (state.user.isLogin) {
                channel && channel.postMessage({type: 'loginStatusChanged', data: {
                    isLogin: false,
                    token: ''
                }, source: window.domLoadTime});
                axios.defaults.headers.common['Authorization'] = '';
            }
        }
        return Object.assign({}, state, {
            token: '', 
            permission: [],
            user: {
                isLogin: false, 
                userInfo: null
            } 
        });
    case SET_USERINFO:
        return Object.assign({}, state, {
            user: {
                isLogin: true,
                userInfo: action.user
            }
        });
    default:
        return state;
    }
};

/**
 * 用户登录
 *  @param {string} type 登录类型 pass/code
 *  @param {string} action type = code 时必填, verify/send
 *  @param {string} name 用户名称 type = pass 时必填
 *  @param {string} password 密码 type = pass 时必填
 *  @param {number} code type = code & action = verify 时必填
 *  @param {number} phone 手机号 type = code 时必填
 *  @returns {object} userInfo 用户信息
 * */ 
export const login = (
    type, 
    action, 
    phone,
    name,
    password,
    code,
    captcha
) => async dispatch => {
    let res;
    if (type === 'pass') {
        res = await axios.post('auth/signin', qs.stringify({
            type,
            name,
            password,
            captchaToken: captcha
        }),{skipAuthRefresh: true});
    }
    dispatch({ type: AUTHENTICATE, token: res.data.token});
    const userInfo = await dispatch(getUserInfo());
    await dispatch(getPermission());
    return userInfo;
};


/**
    获取用户自身信息
*/
export const getUserInfo = () => async dispatch => {
    try {
        const {data} = await axios.get('user/getSelfInfo');
        dispatch({ type: SET_USERINFO, user: data });
        return data;
    } catch {
        
    }
};

/**
    获取用户权限
*/
export const getPermission = () => async dispatch => {
    try {
        const {data} = await axios.get('user/getPermissions');
        dispatch({ type: SET_PERMISSIONS, permission: data });
        return data;
    } catch {
        
    }
};


/** 
    token过期后向服务器请求新的token
    如果服务器返回401则代表会话失效，退出登录 
*/
export const reauthenticate = () => async (dispatch, getState) => {
    const { auth } = getState();
    if (auth.token) {
        try {
            const {data} = await axios.post('auth/getToken', qs.stringify({
                old_token: auth.token
            }), {skipAuthRefresh: true});
            dispatch({ type: AUTHENTICATE, token: data.token });
            return data.token;
        } catch {
            dispatch({ type: DEAUTHENTICATE });
            return;
        }
    }
};

export const logout = () => async dispatch => {
    try {
        await axios.post('auth/signout', null, {skipAuthRefresh: true});
    } catch {

    }
    dispatch({ type: DEAUTHENTICATE });
};


export {
    authReducer as default,
    initialState as authInitialState
};