import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { reauthenticate, logout } from './auth';
import axiosInstance from '../utils/axios';

export const axiosMiddleware = (store) => (next) => (action) => {
    createAuthRefreshInterceptor(axiosInstance, async failedRequest => {
        const token = await store.dispatch(reauthenticate());
        if (token) {
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + token;
        } else {
            store.dispatch(logout());
        }
        return Promise.resolve();
    },{
        pauseInstanceWhileRefreshing: true
    });
    return next(action);
};
