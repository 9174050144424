import NoSsr from '../noSsr';
import { Modal, Text, Button } from '@nextui-org/react';
import styles from './alert.module.scss';
import { FormattedMessage } from 'react-intl';

const Alert = ({title, content, open, onClose}) => {
    return (
        <NoSsr>
            <Modal
                className={styles.modal}
                closeButton
                onClose={() => onClose(false)}
                open={open}
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        {title}
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <span id="modal-content" size={16} style={{textAlign: 'center'}}>
                        {content}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto bordered id="modal-button" onClick={() => onClose(false)}>
                        <FormattedMessage
                            id='general.cancel'
                            defaultMessage='取消'
                        />
                    </Button>
                    <Button auto id="modal-button" onClick={() => onClose(true)}>
                        <FormattedMessage
                            id='general.'
                            defaultMessage='确认'
                        />
                    </Button>
                </Modal.Footer>
            </Modal>
        </NoSsr>
    );
};

export default Alert;