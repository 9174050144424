import * as React from 'react';

import { File, Browser, SpeechBubble, Mug } from 'react-kawaii';
import { Text, Col } from '@nextui-org/react';
import styles from '../../../styles/error.module.scss';
import { FormattedMessage } from 'react-intl';

const Error = (props) => {
    return (
        <div className={styles.content}>
            <Col>
                {props.isDown && (
                    <>
                        <Mug mood="happy" size={160} />
                        <Text size={48}>
                            <FormattedMessage
                                id='general.isdown'
                                defaultMessage='服务器宕机中'
                            />
                        </Text>
                        <Text size={16}> {
                            props.message ? props.message : <FormattedMessage
                            id='general.isdown.desc'
                            defaultMessage='看起来服务器有些疲惫了呢，过一会再来试试吧~'
                        />
                        }
                            
                        </Text>
                    </>
                )}
                {props.login && (
                    <>
                        <File mood="ko" size={160} />
                        <Text size={48}>
                            <FormattedMessage 
                                id='general.requirelogin' 
                                defaultMessage='你还没有登录' 
                            />
                        </Text>
                        <Text size={16}>
                            <FormattedMessage 
                                id='general.requirelogin.desc' 
                                defaultMessage='只有登录用户才能访问这个界面！'
                            />
                        </Text>
                    </>
                )}
                {props.notFound && (
                    <>
                        <Browser mood="sad" size={160} />
                        <Text size={48}>
                            <FormattedMessage 
                                id='general.notfound' 
                                defaultMessage='页面走丢了' 
                            />
                        </Text>
                        <Text size={16}>
                            <FormattedMessage 
                                id='general.notfound.desc' 
                                defaultMessage="你可能在访问不存在的东西呢 (‾◡◝)"
                            />
                        </Text>
                    </>
                )}
                {props.comingSoon && (
                    <>
                        <SpeechBubble mood="happy" size={160} />
                        <Text size={48}>
                            <FormattedMessage 
                                id='general.comingSoon' 
                                defaultMessage='敬请期待' 
                            />
                        </Text>
                        <Text size={16}>
                            <FormattedMessage 
                                id='general.comingSoon.desc' 
                                defaultMessage='前面的区域 以后再来探索吧！' 
                            />
                        </Text>
                    </>
                )}
            </Col>
        </div>
    );
};


export default Error;