import { 
    createStore, 
    applyMiddleware, 
    combineReducers
} from 'redux';
import { createWrapper } from "next-redux-wrapper";
import thunkMiddleware from 'redux-thunk';
import { axiosMiddleware } from './axiosMiddleware';
import modalReducer, {modalInitialState} from './modal';
import authReducer, {authInitialState} from './auth';
import snackBarReducer, {snackBarInitialState} from './snackbar';
import alertReducer, {alertInitialState} from './alert';


const isDevelopment = process.env.NODE_ENV !== 'production' && typeof window !== 'undefined';

const rootReducer = combineReducers({
    modal: modalReducer,
    auth: authReducer,
    snackbar: snackBarReducer,
    alert: alertReducer
});

const preloadedState = {
    modal: modalInitialState,
    auth: authInitialState,
    // snackbar: snackBarInitialState,
    alert: alertInitialState
};


const bindMiddleware = (middleware) => {
    if (isDevelopment) {
        const { composeWithDevTools } = require('redux-devtools-extension');
        const { logger } = require('redux-logger');
        middleware.push(logger);
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

function configureStore (initialState = preloadedState) {
    const store = createStore(
        rootReducer,
        initialState,
        bindMiddleware([ 
            axiosMiddleware,
            thunkMiddleware
        ])
    );
    return store;
}

const makeStore = () => configureStore(preloadedState);

export const wrapper = createWrapper(
    makeStore,
    {debug: isDevelopment}
);