import { useEffect, useState } from "react";
import jsCookie from 'js-cookie';
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import { getCloudConfig } from "src/utils/cloud_config";
import { InfoCircle } from "react-iconly";


const closeTitle = defineMessage({
    id: 'menubar.quiztips.closetitle',
    defaultMessage: '30天内不再提示'
})


export default function QuizTips() {
    const [close, setClose] = useState(false);
    const intl = useIntl();
    useEffect(() => {
        setClose(jsCookie.get('quiztips') === '1');
    }, []);
    
    function handleSetClose() {
        jsCookie.set('quiztips', '1', {
            expires: 30
        });
        setClose(true);
    }
    return (
        <div style={{
            display: close ? 'none' : 'flex',
            position: 'fixed', 
            justifyContent: 'center',
            textAlign: 'center',
            color: 'gray', 
            width: '100%',
            padding: '10px',
            opacity: '0.85',
            zIndex: '900',
            background: '#fff'
        }}>
            <InfoCircle />
            <div style={{paddingRight: '8px', paddingLeft: '5px'}}>
                <FormattedMessage
                    id='menubar.quiztips'
                    defaultMessage='当前账户尚未转正，仅能创建和浏览作品，如需发布作品或留言互动 请点击{here}'
                    values={{
                        here: <a href='/quiz' target='_blank' rel="noreferrer">
                            <FormattedMessage
                                id='menubar.quiztips.link'
                                defaultMessage='这里转正 >>>'
                            />
                        </a>
                    }}
                />
            </div>
            <div 
                onClick={handleSetClose} 
                style={{cursor: 'pointer'}} 
                title={intl.$t(closeTitle)}>×</div>
        </div>
    );
}