import Swal from "sweetalert2";
const t = Swal.mixin({
    toast: true,
    position: "top",
    timerProgressBar: true,
    showConfirmButton: false,
    showCloseButton: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export default function Toast(message, type, timeout = 3000) {
    
    return t.fire({icon: type, title: message, timer: timeout});
}

