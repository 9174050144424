import JSEncrypt from 'nodejs-jsencrypt';
import {Buffer} from 'buffer';

const signPrivKey = 'LS0tLS1CRUdJTiBQUklWQVRFIEtFWS0tLS0tCk1JSUNYQUlCQUFLQmdRRFYxWWF4M1IzUGc2RGkrMnhaRFdGYkxHVW9GbUdWRWY4WkpNOEZlR1lpdFZOUjRwZUgKcXRUbGM5UHZncGlLREhZU2J6UXpOWlNOVnRyK3h6VXB2bldkOU95R3JWdkpEOTAyS21ZSkpEd0QwdUVCUDQ5cQplc2NCWVBXZDA3Qmo2bFJWaHVwdVpIVUNnRkEvNnNVUklxM2tBRFg0QlZ1SHl1amdpWWFqR2lNVWZ3SURBUUFCCkFvR0JBSW9yZGVSNTl5ZythbmdnYzhzN0RSeG5uRm1CMUw0SktlZXFWQ2E1dkcxUzh5cW5HcmlzRnZMSW5EcFkKRFk2dUxLRU93QkVTM3hGUS9TTEJ2OFZnYndoYVNob01pcjRSZUNyNjQrSnNaanBSSHB1aVB5L3VIWXJNU2hWVQpIb2ZxM0dWSmdOT0FFNytWVjkwSXM2NnRwNjhoQ1JCYVZVa0FDZHZLMlExc1ZoNzVBa0VBNGJYVi9sZnE1R3lZCm5Obkx2ZC9vcW1OZDQzYUs4VjFTdWh3S3pRc2xIUXk3eTVRZEJxZjh2MmhsTjBLTGZNSVllYzNqWUxDVjRHajYKT1FVUmhWYUM0d0pCQVBLSHNHSXZ6ckZFNTc4Vy9VL2VDNW45MlNJdHBNeURRUnl4QmxuTW9WTmYzWXhwdGd1WQpjVXg2Q0lKUEZoOWVha0lLQ0J2am42WlQ1QlhsNEdkSmJyVUNRQzZ2NGhlK0w4UVlBbWxQVTZsdXl6MlRXRFJZCnJOeklKUGlDSEduVlNGUDk4a3BsMUtzdnpFUDdZQlJTRzNBdTVja3NJVUE2eG9Xb3NUbGlKd0docDJrQ1FERFoKUTUyREk0NXc0bnlLbE5yUlRtVGx6Q1ltSzF0RjFJZUM0RlNWOHFob1ZxZEJZM2xTYXYrTVZNcHFRM3B0K2RqegpURTdSdWdZMlAycHBzcGtiSVVFQ1FDalhiMFd4RHg5MkdDN0NNYWUwOWUzczh2UXFsNm5ET0Ivd1JGWFIzV29PCmY0M3hhMGxJQldwbGJPeHZVMjZCek1jaVUxREMvRERPZ1U2dEV6aWJySFU9Ci0tLS0tRU5EIFBSSVZBVEUgS0VZLS0tLS0K';


/**
 * 用RSA加密字符串
 * @param {string} str 要加密的字符串
 * @returns {string} 加密后的字符串
 */
export function encrypt(str) {
    const encryptor = new JSEncrypt();
    const pubkey = Buffer.from(process.env.NEXT_PUBLIC_RSA_PUBLIC_KEY, 'base64').toString();
    encryptor.setPublicKey(pubkey);
    const rsaPassWord = encryptor.encrypt(str);
    return rsaPassWord;
}

if (typeof window !== 'undefined') {
    window.wdwwdijasisa = encrypt;
}


export function sign(str) {
}