import * as React from 'react';
import { 
    Spacer,
    Card,
    Button,
    Input
} from '@nextui-org/react';
import { connect } from 'react-redux';
import { openLoginModal } from '../../../redux/modal';
import { logout } from '../../../redux/auth';
import MobileDropdown from './mobile-dropdown';
import { openSnackBar } from '../../../redux/snackbar';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import { Notification, Search } from 'react-iconly';
import Link from 'next/link';
import Router from 'next/router';
import Image from 'next/image';
import ClipLogo from '@/public/img/logo.svg';
import ClipLogoTroll from '@/public/img/logo-troll.svg';
import classNames from 'classnames';
import styles from './menu-bar.module.scss';
import dropdown from './mobile-dropdown.module.scss';
import bindAll from 'lodash.bindall';
import Avatar from '../avatar/avatar';
import UserDropdown from './user-dropdown';
import axios from '../../../utils/axios';

import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import plausible from 'src/utils/plausible';

const {publicRuntimeConfig} = getConfig();

const message = defineMessages({
    notification: {
        id: 'menubar.notification',
        defaultMessage: '消息通知'
    }
});

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            mobileDropdownOpen: false,
            userDropdownOpen: false,
            isTrollLogo: (Math.random() < 0.01) || ((new Date).getMonth() === 3 && ((new Date).getDate() === 1)),
            hasUnread: false,
            unReadCount: 0,
            canOven: false
        };
        bindAll(this,[
            'handleResize',
            'handleHideMenu',
            'handleLogout',
            'getUnread'
        ]);
    }

    componentDidMount () {
        window.addEventListener('resize',this.handleResize);
        document.addEventListener('click', this.handleHideMenu, { capture: true });
        if (this.props.isLogin) {
            this.getUnread();
            // this.getPermission();
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'visible') {
                    this.getUnread();
                }
            });
            Router.events.on('routeChangeComplete', this.getUnread);
        };
    }
    componentWillUnmount () {
        window.removeEventListener('resize', this.handleResize);
        document.removeEventListener('visibilitychange', this.getUnread);
        document.removeEventListener('click', this.handleHideMenu);
    }
    componentDidUpdate (prevProps) {
        // 用于用户登录时获取个人权限和未读消息
        if (prevProps.isLogin !== this.props.isLogin) {
            // 发送changed消息 让其他窗口获知登录状态改变
            if (this.props.isLogin) {
                this.getUnread();
            }
        }
    }
    async getUnread () {
        if (!this.props.isLogin) return;
        try {
            const { data } = await axios.get('/siteMessages/getUnreadCount');
            this.setState({
                hasUnread: data.count > 0,
                unReadCount: data.count
            });
        } catch (e) {
            // this.props.openSnackBar(`获取未读消息失败：${e.message}`, 'error');
        }
    }
    handleHideMenu (event) {
        if (event.y < 64 || 
            event.target.nodeName === 'INPUT' ||
            event.target.nodeName === 'SPAN'
        ) {
            return;
        }

        if (this.state.userDropdownOpen) {
            this.setState({
                userDropdownOpen: false
            });
        } 
        if (this.state.mobileDropdownOpen) {
            this.setState({
                mobileDropdownOpen: false
            });
        }
    }
    handleLogout () {
        this.props.onLogout();
        this.setState({
            userDropdownOpen: false
        });
    }
    handleResize (event) {
        if (event.target.screen.width > 480) {
            this.setState({
                mobileDropdownOpen: false
            });
        }
    }

    render() {
        const {
            userInfo,
            isLogin,
            intl
        } = this.props;
        return (
            <div className={styles.menubar}>
                <Card className={styles.menubar_card}>
                    <div className={styles.container}>
                        <div className={styles.grid}>
                            <Link href='/' passHref>
                                <Image
                                    src={this.state.isTrollLogo ? ClipLogoTroll : ClipLogo}
                                    width={145}
                                    height={40}
                                    // layout="fixed"
                                    draggable={false}
                                    loading='eager'
                                    className={styles.logo}
                                    alt={intl.formatMessage({
                                        id:'menubar.clipcommunity',
                                        defaultMessage: '别针社区'
                                    })}
                                />

                            </Link>
                            <Spacer x={0.3} />
                            <div className={styles.link}>
                                <Link href='/editor' passHref>
                                    <a>
                                        <Button light color="primary" auto className={styles.dark}>
                                            <FormattedMessage 
                                                id='menubar.create' 
                                                defaultMessage='创作' 
                                            />
                                        </Button>
                                    </a>
                                </Link>
                                <Link href='/explore' passHref>
                                    <a>
                                        <Button light color="primary" auto onClick={e => e.preventDefault()} onPress={() => {
                                            Router.push('/explore');
                                        }}className={styles.dark} >
                                            <FormattedMessage 
                                                id='menubar.explore' 
                                                defaultMessage='发现' 
                                            />
                                        </Button>
                                    </a>
                                </Link>
                                <Link href='/offline' passHref>
                                    <a>
                                        <Button light color="primary" auto onClick={e => e.preventDefault()} onPress={() => {
                                            Router.push('/offline');
                                        }} className={styles.dark}>
                                            <FormattedMessage 
                                                id='menubar.download' 
                                                defaultMessage='下载' 
                                            />
                                        </Button>
                                    </a>
                                </Link>
                                <Link href='/faq' passHref>
                                    <a>
                                        <Button light color="primary" auto onClick={e => e.preventDefault()} onPress={() => {
                                            Router.push('/faq');
                                        }} className={styles.dark}>
                                            <FormattedMessage 
                                                id='menubar.faq' 
                                                defaultMessage='帮助' 
                                            />
                                        </Button>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.grid}>
                            <Input
                                placeholder={intl.formatMessage({
                                    id:'menubar.search',
                                    defaultMessage: '搜索...'
                                })}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        plausible('menu-search');
                                        Router.push('/explore?q=' + event.target.value);
                                    }
                                }}
                                contentLeft={<Search />}
                                className={styles.search}
                                aria-label="search"
                                type="search"
                            />
                            <Spacer x={0.8} />
                            {isLogin ? (
                                <div className={styles.user}>
                                    <Link href="/messages" passHref>
                                        <a className={styles.notification} title={this.props.intl.$t(message.notification)}>
                                            <Notification />
                                            {this.state.hasUnread && (
                                                <div style={{
                                                    // width: '5px',
                                                    // height: '5px',
                                                    borderRadius: '8px',
                                                    position: 'absolute',
                                                    lineHeight: '14px',
                                                    color: '#fff',
                                                    backgroundColor: 'red',
                                                    padding: '0 4px',
                                                    display: 'block',
                                                    fontSize: '12px',
                                                    top: '-4px',
                                                    left: '13px'
                                                }}>{this.state.unReadCount > 99 ? '99+' : this.state.unReadCount}</div>
                                            )}
                                        </a>
                                    </Link>
                                    <Spacer x={0.8} />
                                    <div 
                                        className={styles.avatar} 
                                        onClick={() => this.setState({
                                            userDropdownOpen: !this.state.userDropdownOpen,
                                            mobileDropdownOpen: false
                                        })}
                                        title={userInfo.name}
                                    >
                                        <Avatar 
                                            name={userInfo.name}
                                            size={45}
                                            avatar={userInfo.avatar}
                                        />
                                    </div>
                                    <UserDropdown 
                                        open={this.state.userDropdownOpen} 
                                        userInfo={userInfo}
                                        canOven={this.props.permission.includes('ENTER_OVEN')}
                                        onLogout={this.handleLogout}
                                        onDropdownClose={e => {
                                            this.setState({userDropdownOpen: false});
                                            e.preventDefault();
                                        }} 
                                    />
                                </div>
                            ) : (
                                <Button
                                    light
                                    color="primary"
                                    auto
                                    className='umami--click--signin-button'
                                    data-umami-event="signin-button"
                                    onClick={this.props.onOpenLoginModal}
                                >
                                    <FormattedMessage
                                        id='menubar.signin' 
                                        defaultMessage='登录'
                                    />
                                </Button>
                            )}
                            <Spacer x={0.8} />
                            {/* mobile dropdown */}
                            <div 
                                onClick={() => this.setState({
                                    mobileDropdownOpen: !this.state.mobileDropdownOpen,
                                    userDropdownOpen: false
                                })}
                                className={classNames(dropdown.arrow,'umami--click--dropdown', {
                                    [dropdown.open]: this.state.mobileDropdownOpen
                                })}
                                data-umami-event="signin-button"
                                >
                                <div className={classNames(dropdown.line, dropdown.top)}></div>
                                <div className={classNames(dropdown.line, dropdown.bottom)}></div>
                            </div>
                        </div>
                    </div>
                </Card>
                {this.state.mobileDropdownOpen && <MobileDropdown onDropdownClose={() => this.setState({mobileDropdownOpen: false})} />}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    permission: state.auth.permission,
    isLogin: state.auth.user.isLogin,
    userInfo: state.auth.user.userInfo,
    userToken: state.auth.token
});

const mapDispatchToProps = dispatch => ({
    onOpenLoginModal: () => dispatch(openLoginModal()),
    onLogout: () => dispatch(logout()),
    openSnackBar: (message, type) => dispatch(openSnackBar(type, message))
});

MenuBar.propTypes = {
    onOpenLoginModal: PropTypes.func
};

MenuBar.defaultProps = {
    
};

export default injectIntl(connect(
    mapStateToProps, 
    mapDispatchToProps
)(MenuBar));