const OPEN_MODAL = 'modal/openModal';
const CLOSE_MODAL = 'modal/closeModal';



const modal = {
    login: 'login',
    register: 'register',
};

const initialState = {
    [modal.login]: false,
    [modal.register]: false
};


const reducer = function (state = initialState, action) {
    switch (action.type) {
    case OPEN_MODAL:
        return Object.assign({}, state, {
            [action.modal]: true
        });
    case CLOSE_MODAL:
        return Object.assign({}, state, {
            [action.modal]: false
        });
    default:
        return state;
    }
};

export const openModal = function (modal) {
    return {
        type: OPEN_MODAL,
        modal: modal
    };
};

export const closeModal = function (modal) {
    return {
        type: CLOSE_MODAL,
        modal: modal
    };
};

export const openLoginModal = function () {
    return openModal(modal.login);
};
export const closeLoginModal = function () {
    return closeModal(modal.login);
};

export {
    reducer as default,
    initialState as modalInitialState,
};