import React, {useState} from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import {
    Card,
    Input,
    Button,
    Spacer
} from '@nextui-org/react';
import { Search } from 'react-iconly';
import styles from './mobile-dropdown.module.scss';
import { useRouter } from 'next/router';
import { useIntl, FormattedMessage } from 'react-intl';

const MobileDropdown = ({onDropdownClose}) =>{
    const intl = useIntl();
    const Router = useRouter();
    return (
        <>
            <div className={styles.dropdown}>
                <Card 
                    css={{
                        borderRadius: 0,
                        backdropFilter: 'blur(10px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        filter: 'drop-shadow(0 2em 0.8em rgba(0, 0, 0, 0.1))'
                    }}
                    className={styles.card}
                >
                    <Input 
                        contentLeft={<Search />}
                        className={styles.search}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                event.stopPropagation();
                                Router.push('/explore?q=' + event.target.value);
                            }
                        }}
                        placeholder={intl.formatMessage({id:'menubar.search', defaultMessage: '搜索...'})}
                        aria-label="search-mobile"
                    />
                    <Link href='/explore'>
                        <a className={styles.link}>
                            <Button 
                                light 
                                color="primary" 
                                onPress={onDropdownClose}
                            >
                                <FormattedMessage 
                                    id='menubar.explore' 
                                    defaultMessage='发现'
                                />
                            </Button>
                        </a>
                    </Link>
                    <Link href='/offline'>
                        <a className={styles.link}>
                            <Button 
                                light 
                                color="primary" 
                                onPress={onDropdownClose}
                            >
                                <FormattedMessage 
                                    id='menubar.download' 
                                    defaultMessage='下载'
                                />
                            </Button>
                        </a>
                    </Link>
                    <Link href='/faq'>
                        <a className={styles.link}>
                            <Button 
                                light 
                                color="primary" 
                                onPress={onDropdownClose}
                            >
                                <FormattedMessage 
                                    id='menubar.faq' 
                                    defaultMessage='帮助'
                                />
                            </Button>
                        </a>
                    </Link>
                </Card>
            </div>
        </>
    );
};


export default MobileDropdown;