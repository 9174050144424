import Link from 'next/link';
import Image from 'next/image';
import defaultAvatar from '@/public/img/default-avatar.png';
import styles from './avatar.module.scss';
import getConfig from 'next/config';
import PropTypes from 'prop-types';

const {publicRuntimeConfig} = getConfig();

const UserAvatar = ({name, avatar, size}) => {
    let avatarUrl = avatar ? `${publicRuntimeConfig.backend}user/avatar/${avatar}` : defaultAvatar;
    if (avatar.startsWith('https://') || avatar.startsWith('http://')) {
        avatarUrl = avatar;
    }
    return (
        <Image 
            priority
            alt={name}
            src={avatarUrl}
            width={size}
            height={size}
            layout="fixed"
        />
    );};

function Avatar({name, avatar, size, user, passHref}) {
    return (
        <div className={styles.avatar} /*style={{
            width: `${size}px!important`,
            height: `${size}px!important`,
        }}*/>    
            {passHref ? (
                (<Link href={`/user/${user}`} passHref target="_blank">
                    <a>
                        <UserAvatar 
                            name={name}
                            size={size}
                            avatar={avatar}
                        />
                    </a>
                </Link>)
            ): (
                <UserAvatar 
                    name={name}
                    size={size}
                    avatar={avatar}
                />
            )}                   
            
        </div>
    );
}

Avatar.defaultProps = {
    passHref: false,
    avatar: '',
    size: 45,
};

Avatar.propTypes = {
    size: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    user: PropTypes.number,
    passHref: PropTypes.bool
};

export default Avatar;