const OPEN_ALERT = 'alert/open';
const CLOSE_ALERT = 'alert/close';


const initialState = {
    open: false,
    title: '',
    message: ''
};


const reducer = function (state = initialState, action) {
    switch (action.type) {
    case OPEN_ALERT:
        return Object.assign({}, state, {
            open: true,
            title: action.title,
            message: action.message,
            callback: action.callback
        });
    case CLOSE_ALERT:
        return Object.assign({}, state, {
            open: false,
            callback: () => {}
        });
    default:
        return state;
    }
};

export const openAlert = (
    title,
    message
) => (dispatch) => {
    return new Promise(resolve => {
        dispatch({
            type: OPEN_ALERT,
            title,
            message,
            callback: state => {
                dispatch({type: CLOSE_ALERT});
                resolve(state);
            }
        });
    });
};

export const closeAlert = (
) => async (dispatch) => {
    dispatch({
        type: CLOSE_ALERT
    });
};

export {
    reducer as default,
    initialState as alertInitialState,
};