import Toast from "@/utils/toast";

const OPEN_SNACKBAR = 'snackbar/opensnackbar';
const CLOSE_SNACKBAR = 'snackbar/closesnackbar';


const initialState = {
    open: false,
    messageType: 'success',
    timeOutId: null,
    message: ''
};


const reducer = function (state = initialState, action) {
    switch (action.type) {
    case OPEN_SNACKBAR:
        return Object.assign({}, state, {
            open: true,
            timeOutId: action.timeOutId,
            messageType: action.messageType,
            message: action.message,
        });
    case CLOSE_SNACKBAR:
        return Object.assign({}, state, {
            open: false,
            timeOutId: null
        });
    default:
        return state;
    }
};

export const openSnackBar = (
    messageType,
    message, 
    timeout=3000
) => async (dispatch, getState) => {

    Toast(message, messageType, timeout);
    // const oldTimeOut = getState().snackbar.timeOutId;
    // if (oldTimeOut) {
    //     clearTimeout(oldTimeOut);
    // }
    // const timeOutId = setTimeout(
    //     () => dispatch({type: CLOSE_SNACKBAR}), timeout
    // );
    // dispatch({
    //     type: OPEN_SNACKBAR,
    //     messageType,
    //     message,
    //     timeOutId
    // });
};

export {
    reducer as default,
    initialState as snackBarInitialState,
};