import React from 'react';
import classNames from 'classnames';
import {
    Card,
    Button,
} from '@nextui-org/react';
import localeMap from 'locale/index';
import styles from './language-select.module.scss';

const LanguageSelect = ({open, onSelect}) =>{
    return (
        <>
            <div id='language-select' className={classNames(styles.dropdown, {
                [styles.open]: open
            })}>
                <Card className={styles.card}>
                    {Object.keys(localeMap).map((key, index) => (
                        <a key={index}>
                            <Button
                                className={styles.item}
                                light
                                color="primary"
                                onClick={() => onSelect(key)}
                                auto
                            >
                                {localeMap[key].name}
                            </Button>
                        </a>
                    ))}
                </Card>
            </div>
        </>
    );
};


export default LanguageSelect;