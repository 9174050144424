import pino from "pino";

const isProd = process.env.NODE_ENV === 'production';

const pinoConf = {
    level: isProd ? 'info' : 'trace',
    browser: {
        serialize: true,
        disable: isProd
    }
}

if (!isProd) {
    pinoConf.transport = {
        target: 'pino-pretty',
        options: {
            colorize: true
        }
    }
}
const logger = pino();

export default logger;