import getConfig from "next/config";
import qs from "qs";
import logger from "./logger";

const { serverRuntimeConfig } = getConfig();
export default function serverFetch(url,data,method,token) {
    const log = logger.child({url,data,method,token,basePath:serverRuntimeConfig.backend}, {msgPrefix: '[ServerFetch] '});
    return new Promise((resolve, reject) => {
        const fetchOptions = {
            method: method,
            headers: {}
        };
        if (method === 'POST') {
            fetchOptions['data'] = qs.stringify(data);
            fetchOptions['headers']['content-type'] = 'application/x-www-form-urlencoded';
        }
        if (typeof token === 'string') {
            fetchOptions['headers']['Authorization'] = `Bearer ${token}`;
        }
        fetch(`${serverRuntimeConfig.backend}${url}`, fetchOptions)
            .then(async res => {
                if (res.status !== 200) {
                    const a = await res.json();
                    return reject(a);
                }
                return res.json();
            })
            .then(r => {
                log.trace('server response: %s', JSON.stringify(r));
                resolve(r);
            })
            .catch(r => {
                log.error('fetch error: %s', r);
                reject(r);
            });
    });
}