import axios from 'axios';
import getConfig from 'next/config';
import qs from 'qs';
import logger from './logger';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const log = logger.child({}, {msgPrefix: '[axios] '});
const axiosInstance = axios.create({
    baseURL: typeof window === 'undefined' ? serverRuntimeConfig.backend : publicRuntimeConfig.backend,
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000,
    timeoutErrorMessage: '后端连接超时'
});

axiosInstance.interceptors.request.use(function (config) {
    log.debug('request: %j', config.data);
    if (typeof config.data === 'object' && !config.json) {
        config.data = qs.stringify(config.data);
    }
    // if (/*config.sign*/true) {
    //     const sign = Crypto.createHmac('sha256', 'clip').update(config.data).digest().toString('hex');
    //     config.headers['x-sign'] = sign;
    // }
    return config;
});

if (typeof window !== 'undefined') {
    axiosInstance.interceptors.response.use(function (response) {
        log.debug('response: %j', response.data);
        return response;
    }, function (error) {
        const state = __NEXT_DATA__.props.initialState;
        if (error.response.status === 403) {
            if (state.auth.permission.includes('NEWUSER')) {
                error.message = '未转正用户无法进行此操作，请答题转正后再进行操作。';
            } else if (state.auth.permission.includes('STATEMENT')) {
                error.message = '当前账号被禁止发言';
            }
        }
        if (error.response.status === 429) {
            clipSnackbar('error', '操作过于频繁，请稍后再试。');
            error.message = '操作过于频繁，请稍后再试。';
        } 
        if (error.response.data?.message) {
            error.message = error.response.data.message;
        }
        return Promise.reject(error);
    });
}

export default axiosInstance;