import zhCN from './zh-cn.json';
import zhTW from './zh-tw.json';
import ipTranslate from 'src/utils/iplocation-translate.json';
import en from './en.json';


Object.assign(en, ipTranslate.en);

const msg = {
    'en': {name: 'English', message: en},
    'zh-cn': {name: '简体中文', message: zhCN},
    'zh-tw': {name: '繁體中文', message: zhTW}
};

export default msg;