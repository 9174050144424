import cookie from 'cookie';
import jsCookie from 'js-cookie';

/**
 * 是否为浏览器端
 * @returns {boolean}
 * @private
 */
const isBrowser = typeof window !== 'undefined';

/**
 * 获取Cookie
 * @param {string} name Cookie名字
 * @param {import('http').IncomingMessage} req 服务器端req请求，在浏览器端可不填
 * @returns {string|undefined} 返回Cookie的值，如没有则返回undefined
 */
export function getCookie(name, req) {
    if (isBrowser) {
        return jsCookie.get(name);
    } else {
        const reqCookie = req.headers['cookie'];
        if (!reqCookie) return;
        const parseCookie = cookie.parse(reqCookie);
        return parseCookie[name];
    }
}

/**
 * 设置Cookie
 * @param {string} name Cookie的名字
 * @param {string} value Cookie的值
 * @param {boolean} [httpOnly=false] 是否为httpOnly，浏览器端此选项无效
 * @param {boolean} [secure=false] 是否为安全Cookie
 * @param {number} [expires] Cookie有效的时长，不填为会话Cookie
 * @param {import('http').ServerResponse} [res]  服务器端res请求，在浏览器端可不填
 */
export function setCookie(name, value, httpOnly = false, secure = false, expires, res) {
    if (isBrowser) {
        return jsCookie.set(name, value, {
            expires,
            secure,
            path: '/',
            sameSite: 'Lax'
        });
    } else {
        const date = new Date();
        const setCookie = cookie.serialize(name, value, {
            httpOnly,
            expires: expires ? new Date(date.setDate(date.getDate() + expires)) : null,
            secure,
            path: '/',
            sameSite: 'lax'
        });
        res.setHeader('Set-Cookie', setCookie);
    }

}


/**
 * 删除某个Cookie
 * @param {string} name Cookie的名字
 * @param {import('http').ServerResponse} [res]  服务器端res请求，在浏览器端可不填
 */
export function removeCookie(name, res) {
    if (isBrowser) {
        return jsCookie.remove(name);
    } else {
        const setCookie = cookie.serialize(name, 'removed', {
            expires: new Date(0),
            secure: true,
            path: '/'
        });
        res.setHeader('Set-Cookie', setCookie);
    }

}