var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":{"name":"clip-frontend@c816d7a","create":false}};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_URL,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    release: `clip-frontend@${process.env.NEXT_PUBLIC_COMMIT_HASH}`,
    ignoreErrors: [
        /Hydration failed/,
        /Request failed with status code/,
        /Minified React error #423/,
        /Minified React error #418/
    ]
    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    // integrations: [
    //     Sentry.replayIntegration({
    //         // Additional Replay configuration goes in here, for example:
    //         maskAllText: true,
    //         blockAllMedia: true,
    //     }),
    // ],
});
