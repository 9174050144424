import { Col, Text } from "@nextui-org/react";
import { Browser } from 'react-kawaii';
import dayjs from 'dayjs';
import styles from '../../styles/error.module.scss';
import { FormattedMessage } from "react-intl";
import React from 'react';
import logger from "@/utils/logger";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
   
        // Define a state variable to track whether is an error or not
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI
   
        return { hasError: true, errorDetail: error, errorTime: Date.now() };
    }
    componentDidCatch(error, errorInfo) {
        // You can use your own error logging service here
        logger.error({ error, errorInfo });
    }
    render() {
        // Check if the error is thrown
        if (this.state.hasError) {
        // You can render any custom fallback UI
            return (
                <div className={styles.content}>
                    <Col style={{maxWidth: '610px'}}>
                        <Browser mood="ko" size={140} />
                        <Text size={48}>
                            <FormattedMessage
                                id="errorboundary.title"
                                defaultMessage='出错了'
                            />
                        </Text>
                        <Text size={16}>
                            <FormattedMessage
                                id="errorboundary.content"
                                defaultMessage='糟糕！页面崩溃了！这是一个bug，请尝试报告此问题。'
                            />
                        </Text>
                        <Text size={10} color='#686868'>ErrorDetail：{this.state.errorDetail?.message}</Text>
                        {global.navigator && <Text size={10} color='#686868'>UserAgent：{navigator.userAgent}</Text>}
                        {global.navigator && <Text size={10} color='#686868'>UserLocale：{navigator.language}</Text>}
                        <Text size={10} color='#686868'>ErrorTime：{dayjs(this.state.errorTime).format('YYYY-MM-DD HH:mm:ss')}</Text>
                        <Text size={10} color='#686868'>Frontend CommitHash：{process.env.NEXT_PUBLIC_COMMIT_HASH}</Text>
                        <Text size={10} color='#686868'>Frontend BuildTime：{dayjs(parseInt(process.env.NEXT_PUBLIC_BUILD_TIME)).format('YYYY-MM-DD HH:mm:ss')}</Text>
                    </Col>
                </div>
            );
        }
   
        // Return children components in case of no error
   
        return this.props.children;
    }
}
   
export default ErrorBoundary;