// import substances from './cas.json';
import axios from './axios';

async function captcha (method='url') {
    return [null,null];
    // const substance = substances[Math.max(0, Math.floor(Math.random() * substances.length - 1))];
    // const {cas, mf} = substance;
    // if (method === 'url') return [`https://www.chemicalbook.com/CAS/GIF/${cas}.gif`, mf];
    // try {
    //     const { data } = await axios.get(`https://www.chemicalbook.com/CAS/GIF/${cas}.gif`);
    //     return [data, mf];
    // } catch (e) {
    //     throw new Error(`Error while fetching ${cas}`);
    // }
}

export default captcha;