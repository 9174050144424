import {Component} from 'react';
import Router from 'next/router';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Button, Tooltip } from '@nextui-org/react';

import styles from './footer.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { Filter } from 'react-iconly';
import bindAll from 'lodash.bindall';
import Cookies from 'js-cookie';
import LanguageSelect from '../language-select/language-select';
import LanguageSelectIcon from '@/public/img/icons/language-select.svg';

const message = defineMessages({
    settings: {
        id: 'footer.settings',
        defaultMessage: '语言选择'
    }
});

class Footer extends Component {
    constructor (props) {
        super(props);
        this.state = {
            languageOpen: false,
        };
        bindAll(this, ['handleClickSettings', 'handleSelectLanguage', 'handleHideMenu']);
    }
    componentDidMount() {
        document.addEventListener('click', this.handleHideMenu, { capture: true });
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleHideMenu, { capture: true });
    }
    handleHideMenu (event) {
        const path = event.path || (event.composedPath && event.composedPath());
        if (path[0].id !== 'language-select' && !event.target.classList.contains('language-select-icon')) {
            this.setState({
                languageOpen: false
            });
        }
    }
    handleClickSettings () {
        this.setState({
            languageOpen: true
        });
    }
    handleSelectLanguage (lang) {
        this.setState({
            languageOpen: false
        });
        Cookies.set('NEXT_LOCALE', lang, {
            expires: 365
        });
        location.reload();
    }
    render () {
        return (
            <div
                style={{
                    padding: '20px',
                    color: 'white',
                    backgroundColor: '#659af8'
                }}
                className={styles.dark}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    justifyItems: 'content',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Button.Group
                        color="white"
                        light
                    >
                        <Link href='/legal/tos' passHref>
                            <a style={{color: 'white'}}>
                                <Button>
                                    <FormattedMessage 
                                        id='footer.eula' 
                                        defaultMessage='服务条款' 
                                    />
                                </Button>
                            </a>
                        </Link>
                        <Link href='/legal/privacy' passHref>
                            <a style={{color: 'white'}}>
                                <Button>
                                    <FormattedMessage 
                                        id='footer.privacy' 
                                        defaultMessage='隐私政策' 
                                    />
                                </Button>
                            </a>

                        </Link>
                        <Link href='/about' passHref>
                            <a style={{color: 'white'}}>
                                <Button>
                                    <FormattedMessage 
                                        id='footer.about' 
                                        defaultMessage='关于' 
                                    />
                                </Button>
                            </a>
                        </Link>
                        <Link href='/legal/rule' passHref>
                            <a style={{color: 'white'}}>
                                <Button>
                                    <FormattedMessage 
                                        id='footer.rule' 
                                        defaultMessage='守则' 
                                    />
                                </Button>
                            </a>
                        </Link>
                    </Button.Group>
                    <Tooltip content={this.props.intl.$t(message.settings)}>
                        <Button onPress={this.handleClickSettings} className='language-select-icon' auto color="white" light size='xs'>
                            {/* <Filter set="curved" className='language-select-icon'/> */}
                            <Image src={LanguageSelectIcon} layout='fill' className='language-select-icon' alt='language'/>
                        </Button>
                    </Tooltip>
                </div>
                <LanguageSelect open={this.state.languageOpen} onSelect={this.handleSelectLanguage} />
                <div style={{
                    textAlign: 'center',
                    height: '10px'
                }}>
                    <span>A Scratch community made with love.</span>
                </div>
                <div
                    style={{
                        color: 'white',
                        textAlign: 'center',
                        margin: '20px 0 10px 0',
                    }}
                >
                    Copyright &copy; 2018-{new Date().getFullYear()}&nbsp;
                    <span><strong>上海呜呜呜网络科技有限公司</strong> All rights reserved.</span>
                </div>
                <div
                    style={{
                        color: 'white',
                        textAlign: 'center',
                        margin: '20px 0 10px 0',
                    }}
                >
                    <a
                        style={{color: 'white'}}
                        target="_blank"
                        rel="noreferrer"
                        href="https://beian.miit.gov.cn/" 
                    >
                        沪ICP备2023012822号-1
                    </a>
                </div>
            </div>
        );
    }
}

export default injectIntl(Footer);
