import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import {
    Card,
    Button,
} from '@nextui-org/react';
import { FormattedMessage } from 'react-intl';
import { 
    User,
    Setting,
    Folder,
    Work,
    ArrowRightSquare
} from 'react-iconly';
import styles from './user-dropdown.module.scss';
import Router from 'next/router';

const UserDropdown = ({open, userInfo, onDropdownClose, onLogout, canOven}) =>{
    return (
        <>
            <div className={classNames(styles.dropdown, {
                [styles.open]: open
            })}>
                <Card className={styles.card}>
                    <Link href={`/user/${userInfo.id}`} passHref>
                        <a>
                            <Button 
                                className={styles.item}
                                light 
                                color="primary" 
                                onClick={onDropdownClose}
                                onPress={() => {
                                    Router.push(`/user/${userInfo.id}`);
                                }}
                                auto
                            >
                                <User /> 
                                <FormattedMessage 
                                    id='userdropdown.profile' 
                                    defaultMessage='个人资料' 
                                />
                            </Button>
                        </a>
                    </Link>
                    <Link href='/stuff' passHref>
                        <a>
                            <Button 
                                className={styles.item}
                                light 
                                onClick={onDropdownClose}
                                onPress={() => {
                                    Router.push('/stuff');
                                }}
                                color="primary" 
                                auto
                            >
                                <Folder /> 
                                <FormattedMessage 
                                    id='userdropdown.mystuff' 
                                    defaultMessage='我的作品' 
                                />
                            </Button>
                        </a>
                    </Link>
                    {canOven && (
                        <Link href='/admin/dashboard' passHref>
                            <a>
                                <Button
                                    className={styles.item}
                                    light
                                    onClick={onDropdownClose}
                                    color="primary"
                                    onPress={() => {
                                        Router.push('/admin/dashboard');
                                    }}
                                    auto
                                >
                                    <Work />
                                    <FormattedMessage
                                        id='userdropdown.dashboard'
                                        defaultMessage='管理面板'
                                    />
                                </Button>
                            </a>
                        </Link>
                    )}
                    <Link href='/settings' passHref>
                        <a>
                            <Button 
                                className={styles.item}
                                light 
                                color="primary" 
                                onClick={onDropdownClose}
                                onPress={() => {
                                    Router.push('/settings');
                                }}
                                auto
                            >
                                <Setting /> 
                                <FormattedMessage 
                                    id='userdropdown.settings' 
                                    defaultMessage='用户设置' 
                                />
                            </Button>
                        </a>
                    </Link>
                    <a>
                        <Button
                            className={styles.item}
                            light
                            color="primary"
                            onClick={onLogout}
                            auto
                        >
                            <ArrowRightSquare />
                            <FormattedMessage
                                id='userdropdown.logout'
                                defaultMessage='退出登录'
                            />
                        </Button>
                    </a>
                </Card>
            </div>
        </>
    );
};

UserDropdown.messages = ['userdropdown'];

export default UserDropdown;