import '../styles/globals.scss';
import 'sweetalert2/src/sweetalert2.scss';
import '../styles/swal-styles.scss';
import App from 'next/app';
import { createTheme, NextUIProvider } from '@nextui-org/react';
import getConfig from 'next/config';
import {SSRProvider} from '@react-aria/ssr';
import { IntlProvider } from 'react-intl';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import {wrapper} from '../redux/store';
import Layout from '../components/layout/layout';
import PlausibleProvider from 'next-plausible';

import {getUserInfo} from '../redux/auth';
import Cookie from 'cookie';
import BrowserUpdate from '@/components/Core/menu-bar/browser-update';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'locale/index';
import qs from 'qs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/en';
import plausible from 'src/utils/plausible';

import Request from '@/components/Core/request/request';
import getUser from 'src/utils/auth';
import { setCookie } from 'src/utils/cookie';
import { setCloudConfig } from 'src/utils/cloud_config';
import ErrorBoundary from '@/components/layout/ErrorBoundary';
import polyfill from 'src/utils/polyfill';

if (typeof window !== 'undefined') {
    polyfill();
}

// 去掉烦人的提示
const consoleWarn = console.warn.bind(console);
// eslint-disable-next-line
    console.warn = (message, ...args) => {
    if (
        typeof message === 'string' &&
            (
                message.startsWith('When server rendering') ||
                message.startsWith('If you do not provide a visible label') ||
                message.startsWith('onClick is deprecated')
            )
    ) {
        return;
    }
    consoleWarn(message, ...args);
};

// const NotoFont = Noto_Sans({
//     variable: '--noto-font',
//     subsets: ['latin'],
//     weight: ['400', '500']
// });
// const NotoSCFont = Noto_Sans_SC({
//     variable: '--notosc-font',
//     subsets: ['latin'],
//     weight: ['400']
// });

// const EmojiFont = Noto_Color_Emoji({
//     variable: '--emoji-font',
//     subsets: ['emoji'],
//     weight: '400'
// });

const { publicRuntimeConfig } = getConfig();
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

const lightTheme = createTheme({type: 'light'});
const darkTheme = createTheme({
    type: "dark",
    className: "dark",
    theme: {
        colors: {
            
        }
    }
});

class ClipFrontend extends App {
    static getInitialProps = wrapper.getInitialAppProps(
        store => async context => {
            if (typeof window === 'undefined') {
                // l10n
                let acceptLanguage;
                let cookie = {};
                const queryParams = qs.parse(context.ctx.req.url.split('?')[1]);
                try {
                    cookie = Cookie.parse(context.ctx.req.headers['cookie']||'');
                    acceptLanguage = context.ctx.req.headers['accept-language'].split(',')[0];
                } catch {
                    acceptLanguage = 'en';
                }
                let locale = cookie['NEXT_LOCALE'] || acceptLanguage.toLowerCase();
                if (queryParams.locale) {
                    locale = queryParams.locale;
                    setCookie('NEXT_LOCALE', locale, false, false ,null, context.ctx.res);
                }
                if (locale == 'zh-hant') {
                    locale = 'zh-tw';
                } else if (locale == 'zh-hans') {
                    locale = 'zh-cn';
                } else if (locale == 'fr') {
                    locale = 'en';
                }
                if (!localeData.hasOwnProperty(locale)) {
                    locale = 'zh-cn';
                } else {
                    const splitLocale = locale.split('-')[0];
                    if (localeData.hasOwnProperty(splitLocale)) {
                        locale = splitLocale;
                    }
                }
                // // browser check
                // let browserSupport = true;
                // const userAgent = context.ctx.req.headers['user-agent'];
                // // 老旧的IE浏览器 该升级了 跳转到升级页面 Edge为旧版
                // if (userAgent && (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf("Trident") > -1)) {
                //     context.ctx.res.statusCode = 302;
                //     context.ctx.res.setHeader('Location', `/unsupported.html?url=${encodeURIComponent(context.ctx.asPath)}`);
                // } else if (userAgent && userAgent.indexOf('Edge') > -1) {
                //     browserSupport = false;
                // }
                // let cfdata;
                // try {
                //     cfdata = await axios.get('app/getServerConfig');
                // } catch {}
                // // catch就是服务端挂了，返回维护页面
                try {
                    await getUser(context.ctx.req, context.ctx.res, store);
                    
                } catch {
                    context.ctx.res.statusCode = 503;
                    return {
                        locale: locale,
                        isDown: true
                    };
                }
                return {
                    pageProps: {
                        // https://nextjs.org/docs/advanced-features/custom-app#caveats
                        ...(await App.getInitialProps(context)).pageProps,
                    },
                    locale: locale,
                    // buildMetadata: {
                    //     commitHash: process.env.NEXT_PUBLIC_COMMIT_HASH,
                    //     buildTime: parseInt(process.env.NEXT_PUBLIC_BUILD_TIME),
                    //     hostName: process.env.NEXT_PUBLIC_HOST_NAME
                    // },
                    // cfdata: cfdata ? Buffer.from(cfdata.data.cloud_config).toString('hex') : null,
                    // browserSupported: browserSupport,
                    // deviceData: {}
                };
            } else {
                const {token} = store.getState().auth;
                if (token) {
                    await store.dispatch(getUserInfo());
                }
                if (process.env.NODE_ENV === 'production') {
                    console.warn = () => {};
                    console.log = () => {};
                }
            }
        });
    componentDidMount() {
        
        /*
        if (this.props?.deviceData) {
            const os = `${this.props.deviceData.os.name} ${this.props.deviceData.os.version}`;
            const device = `${this.props.deviceData.device.brand} ${this.props.deviceData.device.model}`;
            plausible('deviceData', {
                os: os,
                device: this.props.deviceData.device.model ? device : null
            });
        } 
        */
    }

    render() {
        const { Component, pageProps, locale, browserSupported, isDown, cfdata} = this.props;
        if (locale) this.locale = locale;
        cfdata && setCloudConfig(cfdata);
        dayjs.locale(this.locale);
        return (
            <SSRProvider>
                <IntlProvider 
                    locale={this.locale} 
                    messages={localeData[this.locale]?.message || localeData['en']?.message}
                    onError={(err) => {
                        if (err.code === "MISSING_TRANSLATION") {
                            return;
                        }
                    }}
                > 
                    <ErrorBoundary>
                        <NextThemesProvider
                            defaultTheme="system"
                            enableSystem
                            attribute="class"
                            value={{
                                light: lightTheme.className,
                                dark: darkTheme.className
                            }}
                        >
                            <NextUIProvider>
                                {/*<style jsx global>{`
                                :root {
                                    --noto-font: ${NotoFont.style.fontFamily},${NotoSCFont.style.fontFamily};
                                    --emoji-font: ${EmojiFont.style.fontFamily};
                                }
			                `}</style>}
                                {/* <PlausibleProvider 
                                trackOutboundLinks
                                domain='community.codingclip.com' 
                                selfHosted
                                customDomain={publicRuntimeConfig.plausible}
                            > */}
                                {isDown ? <Request isDown /> : (
                                    <Layout {...pageProps}>
                                        {/* {!this.browserSupported && <BrowserUpdate />} */}
                                        <Component {...pageProps} />
                                    </Layout>
                                )}
                                {/* </PlausibleProvider> */}
                            </NextUIProvider>
                        </NextThemesProvider>
                    </ErrorBoundary>
                </IntlProvider>
            </SSRProvider>
        );
    }
}

export default wrapper.withRedux(ClipFrontend);



